// If you don't want to use TypeScript you can delete this file!
import * as React from "react"
import { PageProps, Link, graphql } from "gatsby"

import Layout from "../layouts/default"
import Seo from "../components/seo"
import tw, { styled } from 'twin.macro'

import { Cloud, Speedometer, Keyboard } from 'react-bootstrap-icons'

import logo from '../images/black-chip-logo.svg';
import logo_white from '../images/black-chip-logo--white.svg';

type DataProps = {
  data: object,
  path: string
}

const Page: React.FC<PageProps> = ({ data: { page }, path }) => (
  <Layout>
    {/*<Seo title={page.h1} description="Typescript application development studio, New Zealand" keywords="typescript, gatsby, nextjs, remix, react" />*/}
    <Splash>
      <header>
      <strong style={{'--bg': `url("${logo}")`, '--bg-white': `url("${logo_white}")`}} tw="block mx-auto font-medium font-display text-4xl md:text-5xl xl:text-6xl tracking-tight antialiased flex gap-x-3 items-center">
        <Link to="/">BlackChip</Link>
      </strong>
      <h1>Sophisticated Cloud Software, made in <mark>New Zealand</mark></h1>
      
      </header>
      <main>
        <h1>{page.h1}</h1>
      </main>
      
    </Splash>
  </Layout>
)

export default Page

export function Head({ data: { page: { meta: { tags } } }}) {
    return (
        <>
            {tags.map(({ tagName, content, attributes }, i) => (
                React.createElement(tagName, {...attributes}, content || null)
            ))}
        </>
    )
  }

export const query = graphql`
    query LandingPage( $id: String! ) {
        page:datoCmsPage( originalId: {eq: $id} ) {
            id
            h1
            meta:seoMetaTags {
                tags
            }
        }
    }
`

const Splash = styled.div`
  ${tw `min-h-screen flex flex-col items-center justify-start flex-wrap`}

  ::selection {
    background:red;
  }

  strong::before {
    ${tw `
      content
      width[1em] 
      height[1em] 
      block 
      bg-no-repeat 
      bg-cover 
      bg-center 
      backgroundImage[var(--bg)]
      dark:backgroundImage[var(--bg-white)]
    `}
  }

  strong span::after,
  strong a::after {
    ${tw `content["."]`}
  }

  header {
    ${tw `flex justify-center flex-col items-center gap-y-4 mt-10 lg:mt-16`}


  }

  h1 {
    ${tw `
      text-center 
      font-display 
      font-light 
      text-2xl 
      antialiased
      max-w-[22ch]
    `}

    mark {
      ${tw `text-transparent bg-clip-text bg-gradient-to-br from-teal-400 to-blue-600
      `}
    }
  }
  
  ul {
    ${tw `flex items-center justify-center gap-x-6`}
  }


  main {
    ${tw `font-display font-light mt-20`}

    h2 {
      ${tw `font-light text-3xl`}
    }
  }

  .product {
    ${tw `mt-5 lg:mt-12`}

    > h2 {
      ${tw `tracking-widest uppercase text-xs dark:text-neutral-200 flex items-center`}

      &::before,
      &::after {
        ${tw `content h-px w-[calc(100% - 1rem)] bg-zinc-300 dark:bg-zinc-700`}
      }

      &::before {
        ${tw `mr-4`}
      }

      &::after {
        ${tw `ml-4`}
      }
    }

    ol {
      ${tw `list-none flex flex-wrap justify-center items-start gap-y-10 max-w-3xl gap-x-10 p-0 my-10`}

      li {
        ${tw `text-center w-full md:flex-1`}
      }

      svg {
        ${tw `w-16 h-16 align-top block mb-5 mx-auto`}
      }

      a {
        ${tw `underline`}
        text-underline-offset: .25em;
      }

      p {
        ${tw `leading-relaxed max-w-[30ch] mx-auto`}
      }
    }
  }
`