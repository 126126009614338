/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

 import * as React from "react"
 import PropTypes from "prop-types"
 import { PageProps } from "gatsby"
 import tw, { styled } from 'twin.macro'
 
 type LayoutProps = {
    children: Function
  }
 
  const Layout: React.FC<PageProps<LayoutProps>> = ({ children }) => (
        <Style>
            {children}
        </Style>
  )
 
 Layout.propTypes = {
   children: PropTypes.node.isRequired,
 }
 
 export default Layout
 
const Style = styled.div`
 ${tw `container mx-auto px-8`}
`